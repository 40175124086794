<template>
  <div id="buyer">
    <div class="tabbar">
      <div :class="item===selectItem?'isAcitve':''" v-for="(item,index) in tabbarItem" :key="index" @click="clickItem(item,index)">
        {{item}}
      </div>
    </div>
    <div class="filter">
      <div class="select_ground">
        <div class="select_data">
          <el-select v-model="value" :popper-append-to-body="false" placeholder="选择时间" @change="selectTimeChange">
            <el-option
              v-for="(item,index) in options"
              :key="index"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="select_game">
          <el-select v-model="selectGameValue" clearable :popper-append-to-body="false" placeholder="选择游戏" @change="selectGameChange">
            <el-option
              v-for="(item,index) in selectGame"
              :key="index"
              :label="item.label"
              :value="{value:item.value,label:item.label}">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="filter_search">
        <input type="text" class="filter_search_input" v-model="match" placeholder="请输入账户关键词">
        <span class="filter_search_btn" @click="clickMatch">确认</span>
      </div>
    </div>
    <div class="product_list">
      <el-table
        :data="tableData.slice((currentPage-1)*PageSize,currentPage*PageSize)"
        @row-click="tablaRowClick"
        style="width: 100%">
        <!-- 空数据 -->
        <template slot="empty">
          <div class="no_data">
            <img src="@/assets/image/PersonalCenter/Seller/noData.png" alt="">
          </div>
        </template>
        <!-- 商品信息 -->
        <el-table-column
          label="商品信息"
          width="480">
          <template slot-scope="scope">
            <div class="product">
              <div class="product__img">
                <el-image :src="scope.row.image"></el-image>
<!--                <img v-if="scope.row.image" :src="scope.row.image" alt="">-->
<!--                <img v-else src="https://images.pxb7.com/images/equip/20220214/20220214170100_361766.png" alt="">-->
              </div>
              <div class="product_info">
                <div class="product_info_top">
                  订单号：{{ scope.row.order_no }}
                </div>
                <div class="product_info_mid">
                  <span>{{ scope.row.game_name }}</span>
                  {{ scope.row.name }}
                </div>
                <div class="product_info_bot">
                  下单时间：{{ scope.row.add_time|moments }}
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <!-- 购买数量 -->
        <el-table-column
          label="购买数量">
          <template slot-scope="scope">
            <div class="product_num">{{scope.row.number}}</div>
          </template>
        </el-table-column>
        <!-- 金额 -->
        <el-table-column
          label="金额">
          <template slot-scope="scope">
            <div class="product_price">¥{{scope.row.deal_money}}</div>
          </template>
        </el-table-column>
        <!-- 状态 -->
        <el-table-column
          label="状态"
          width="111">
          <!-- eslint-disable-next-line -->
          <template slot-scope="scope">
            <div slot="reference" class="name-wrapper">
              <el-tag v-if="scope.row.billStatus == '1'" type="error" size="medium">待付款</el-tag>
              <el-tag v-if="scope.row.billStatus == '2'" type="primary" size="medium">待发货</el-tag>
              <el-tag v-if="scope.row.billStatus == '3'" type="success" size="medium">已成交</el-tag>
              <el-tag v-if="scope.row.billStatus == '4'" type="info" size="medium">已取消</el-tag>
            </div>
          </template>
        </el-table-column>
        <!-- 操作 -->
        <el-table-column
          label="操作"
          width="100">
          <template >
            <div class="btn_ground">
<!--              <a v-if="scope.row.game_id == 10" class="modify" @click.stop="cusSerBtnClicks(scope.row)">咨询客服</a>-->
<!--              <a v-else class="ask_service" @click.stop="$accessLink(scope.row.game_customer)" :href="`${scope.row.game_customer}?u_cust_id=${$store.state.userData.user_id}&u_cust_name=${$store.state.userData.nickname}`" target="_blank">咨询客服</a>-->
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pages" v-if="totalCount !== 0">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-size="PageSize"
          layout="prev, pager, next, jumper"
          :total="totalCount">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import {apiGameList,apiBuyEquipList} from "@/request/API";

export default {
  name: '',
  props: {
  },
  components: {

  },
  data () {
    return {
      // 默认显示第几页
      currentPage:1,
      // 总条数，根据接口获取数据长度(注意：这里不能为空)
      totalCount:0,
      // 默认每页显示的条数（可修改）
      PageSize:10,
      // tabbar 选中项
      selectItem:'全部订单',
      // tabbar 选中项Index
      selectItemIndex: 0,
      tabbarItem:[
        '全部订单','待付款','待发货','已成交','已取消',
      ],
      //表格数据
      tableData: [],
      //选择时间下拉框
      options: [
        {value: '0', label: '全部'},
        {value: '1', label: '本周内'},
        {value: '2', label: '当月'},
        {value: '3', label: '三个月内'},
        {value: '4', label: '半年内'},
        {value: '5', label: '半年以上'},
        {value: '6', label: '一年内'},
      ],
      value: '',
      //选择游戏下拉框
      selectGame: [],
      selectGameValue: '',
      //关键词搜索
      match:'',
      //当前页码
      page:1
    }
  },
  methods: {
    cusSerBtnClicks(item) {
      let routeData = this.$router.resolve({
        name: 'CustomerSer',
        query: {
          game_id: item.game_id
        }
      })
      window.open(routeData.href, '_blank')
    },
    //table监听事件
    handleEdit(index, row) {
    },
    handleCurrentChange(val) {

    },
    //表格 row 单击事件
    tablaRowClick(row, column, event){
      this.$router.push({
        name:'EquipmentDetails',
        query:{
          game_id:row.game_id,
          id:row.product_id
        }
      });
    },
    //tabbar
    clickItem(item, index) {
      //游戏列表  下拉框
      apiGameList({
      }).then(res=>{
        res.data.forEach((item,index)=>{
          let options = new Object()
          options.value = item.game_id
          options.label = item.game_name
          this.selectGame.push(options)
        })
      })
      this.selectItem = item
      this.selectItemIndex = index
      this.getAccountList(this.value,this.selectGameValue.value,this.match,this.selectItemIndex)
    },
    //选择时间 下拉框 监听
    selectTimeChange(){
      this.getAccountList(this.value,this.selectGameValue.value,this.match,this.selectItemIndex)
    },
    //选择游戏 下拉框 监听
    selectGameChange(event){
      this.getAccountList(this.value,this.selectGameValue.value,this.match,this.selectItemIndex)
    },
    //关键词搜索
    clickMatch(){
      this.getAccountList(this.value,this.selectGameValue.value,this.match,this.selectItemIndex)
    },
    /**
     * 获取已成交账号列表
     * @param timeType 时间下拉框
     * @param game_id  游戏下拉框
     * @param match    关键词
     */
    getAccountList(timeType,game_id,match,billStatus){
      apiBuyEquipList({
        timeType:timeType,
        game_id:game_id,
        match:match,
        billStatus:billStatus
      }).then(res=>{
        this.tableData = res.data.list
        this.totalCount = res.data.total
      })
    },
    //初始化数据
    onLoad(){
      //游戏列表  下拉框
      apiGameList({
      }).then(res=>{
        res.data.forEach((item,index)=>{
          let options = new Object()
          options.value = item.game_id
          options.label = item.game_name
          this.selectGame.push(options)
        })
      })
      // 已成交 账号列表
      apiBuyEquipList({
        billStatus:'0'
      }).then(res=>{
        this.tableData = res.data.list
        this.totalCount = res.data.total
      })
    }
  },
  mounted () {
    //初始化数据
    if(typeof(this.$route.params.selectItem) !== 'undefined'){
      this.clickItem(this.$route.params.selectItem,this.$route.params.selectItemIndex)
    }else {
      this.onLoad()
    }
  },
  watch: {

  },
  computed: {

  }
}
</script>

<style lang='less'>
.change_price_confirm{
  width: 500px !important;
  height: 320px !important;
  background: #FFFFFF !important;
  border-radius: 10px !important;
  padding: 0;
  border: none;
  .el-message-box__header>.el-message-box__title{
    display: none;
  }
  .el-message-box__header,.el-message-box__content{
    display: flex;
    flex-direction: column;
    padding: 0 0 0 70px;
  }
  .el-message-box__btns{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
  }
  .el-message-box__title{
    margin-top: 38px;
    font-size: 20px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 400;
    color: #5B5B5B;
    line-height: 28px;
  }
  .el-message-box__container{
    margin-top: 34px;
    font-size: 16px;
    font-family: PingFangSC-Thin, PingFang SC;
    font-weight: 300;
    color: #5B5B5B;
    line-height: 22px;
  }
  .change_price_confirm_btn{
    margin-top: 20px;
    padding: 0;
    width: 200px;
    height: 40px;
    line-height: 40px;
    background: #FFDB51 !important;
    border-radius: 28px;
    border: none;
    font-size: 18px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 400;
    color: #000000 !important;
  }
  .change_price_confirm_btn:hover{
    background: #FFDB51 !important;
  }
  .confirm_content{
    display: flex;
    flex-direction: column;
  }
  .confirm_original_price{
    font-size: 20px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 400;
    color: #FC4A00;
    line-height: 28px;
    margin-bottom: 20px;
  }
  .confirm_my_bid{
    font-size: 20px;
    font-family: PingFangSC-Thin, PingFang SC;
    font-weight: 300;
    color: #5B5B5B;
    line-height: 28px;
    margin-bottom: 20px;
  }
  .confirm_modify_bid{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .confirm_modify_bid_text{
    font-size: 20px;
    font-family: PingFangSC-Thin, PingFang SC;
    font-weight: 300;
    color: #5B5B5B;
    line-height: 28px;
  }
  .confirm_modify_bid_input{
    width: 210px;
    height: 40px;
    background: #F9F9F9;
    border-radius: 4px;
    border: 1px solid #F0F0F0;
    padding: 0 20px;
    font-size: 16px;
    font-family: PingFangSC-Thin, PingFang SC;
    font-weight: 300;
    line-height: 22px;
  }
  .confirm_modify_bid_tips{
    font-size: 16px;
    font-family: PingFangSC-Thin, PingFang SC;
    font-weight: 300;
    color: #BFBFBF;
    line-height: 22px;
    margin-left: 100px;
  }
  .confirm_modify_bid_input::-webkit-input-placeholder {
    font-size: 16px;
    font-family: PingFangSC-Thin, PingFang SC;
    font-weight: 300;
    color: #D1D1D1;
  }
}
</style>

<style lang='less' scoped>
#buyer{
  padding: 0 22px;
  display: flex;
  flex-direction: column;
  .tabbar{
    margin-top: 22px;
    height: 58px;
    background: #F4F4F4;
    border-radius: 10px;
    padding: 0 45px;
    display: flex;
    flex-direction: row;
    align-items: center;
    div{
      font-size: 16px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      color: #5B5B5B;
      margin-right: 69px;
      cursor: pointer;
    }
    .isAcitve{
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 500;
      color: #5B5B5B;
      padding-bottom: 6px;
      background: url("../../../assets/image/PersonalCenter/Buyer/selectItem.png") no-repeat;
      background-position:bottom;
    }
    div:last-of-type{
      margin: 0;
    }
  }
  .filter{
    margin: 27px 0 43px 0;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .select_ground{
      display: flex;
      flex-direction: row;
      /deep/.el-input__inner{
        width: 180px;
        height: 40px;
        border-radius: 4px;
        border: 1px solid #DCDFE6;
      }
      /deep/.el-input.is-focus .el-input__inner{
        border: 1px solid #FCAD13;
      }
      /deep/.popper__arrow{
        left: 155px !important;
      }
      /deep/.el-select-dropdown__item.selected{
        color: #606266;
        font-weight: 400;
      }
      .select_data{
        margin-right: 22px;
      }
      .select_game{

      }
    }
    .filter_search{
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
      .filter_search_input{
        width: 160px;
        height: 40px;
        background: #F4F4F4;
        border-radius: 10px;
        border: none;
        padding: 0 120px 0 20px;
        font-size: 16px;
        font-family: PingFangSC-Thin, PingFang SC;
        font-weight: 300;
      }
      .filter_search_input::-webkit-input-placeholder{
        font-size: 16px;
        font-family: PingFangSC-Thin, PingFang SC;
        font-weight: 300;
        color: #CECECE;
      }
      .filter_search_btn{
        position: absolute;
        top: 0;
        left: 200px;
        width: 100px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background: linear-gradient(180deg, #5182FF 0%, #434ED6 100%);
        border-radius: 10px 10px 10px 10px;
        font-size: 20px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        cursor: pointer;
      }
    }
  }
  .product_list{
    /deep/.el-table th{
      height: 50px;
      background: #F4F4F4;
      font-size: 16px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      color: #5B5B5B;
    }
    /deep/.el-table th>.cell{
      text-align: center;
    }
    /deep/.el-table .cell{
      padding: 0;
      text-align: center;
    }
    /deep/.el-table td, .el-table th{
      padding: 0;
    }
    //状态
    /deep/.el-tag{
      font-size: 16px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 400;
      background: #D8F1FC;
      color: #16559D;
    }
    /deep/.el-tag.el-tag--danger{
      padding: 0 2px;
      background: #FCD8D8;
      color: #B90101;
    }
    /deep/.el-tag.el-tag--info{
      background: #F0F0F0;
      color: #BFBFBF;
    }
    /deep/.el-tag.el-tag--success{
      background: #D8FCDE;
      color: #389D16;
    }
    /deep/.el-tag.el-tag--warning{
      background: #FFF2D9;
      color: #FCAD13;
    }
    //商品信息
    .product{
      height: 170px;
      display: flex;
      flex-direction: row;
      align-items: center;
      .product__img{
        height: 130px;
        width: 130px;
        margin-right: 20px;
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 10px;
        }
        /deep/.el-image{
          height: 100%;
          width: 100%;
          object-fit: cover;
          border-radius: 10px;
        }
      }
      .product_info{
        width: 330px;
        display: flex;
        flex-direction: column;
        height: 130px;
        justify-content: space-around;
        .product_info_top{
          flex-shrink: 0;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 600;
          color: #5B5B5B;
          text-align: left;
          margin: 0 0 10px 0;
        }
        .product_info_mid{
          font-size: 16px;
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 400;
          color: #5B5B5B;
          line-height: 22px;
          height: 66px;
          text-align: left;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
          margin-bottom: 10px;
          span{
            padding: 1px 7px;
            font-size: 14px;
            font-family: PingFangSC-Light, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 20px;
            background: #FCAD13;
            border-radius: 15px;
          }
        }

        .product_info_bot{
          font-size: 16px;
          font-family: PingFangSC-Thin, PingFang SC;
          font-weight: 100;
          color: #8F8F8F;
          line-height: 22px;
          text-align: left;
        }
      }
    }
    //购买数量
    .product_num{
      font-size: 18px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      color: #5B5B5B;
    }
    //金额
    .product_price{
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 500;
      color: #FC4A00;
    }
    //我的出价
    .my_bid{
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 80px;
      color: #BFBFBF;
      .my_bid_text{
        font-size: 16px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 400;
        line-height: 22px;
        margin-bottom: 10px;
      }
      .my_bid_date{
        width: 85px;
        font-size: 14px;
        font-family: PingFangSC-Thin, PingFang SC;
        font-weight: 300;
        color: #BFBFBF;
        line-height: 20px;
      }
    }
    //操作
    .btn_ground{
      display: flex;
      flex-direction: column;
      div,a{
        height: 30px;
        line-height: 30px;
        cursor: pointer;
        font-size: 16px;
        font-family: PingFangSC-Thin, PingFang SC;
        font-weight: 300;
        color: #000000;
        border-radius: 20px;
      }
      .ask_service{
        margin: 4px 0 0 0;
        background: #FFDB51;
      }
      .change_price{
        border: 1px solid #000000;
      }
      .agree_state{
        display: flex;
        flex-direction: column;
        height: 50px;
        align-items: center;
        img{
          width: 26px;
          height: 26px;
          object-fit: cover;
          margin-bottom: 2px;
        }
        .state_text{
          font-size: 16px;
          font-family: PingFangSC-Thin, PingFang SC;
          font-weight: 300;
          color: #9B9B9B;
          line-height: 22px;
        }
      }
      .refuse_state{
        display: flex;
        flex-direction: column;
        height: 50px;
        align-items: center;
        margin: 0 0 12px 0;
        img{
          width: 26px;
          height: 26px;
          object-fit: cover;
          margin-bottom: 2px;
        }
        .state_text{
          font-size: 16px;
          font-family: PingFangSC-Thin, PingFang SC;
          font-weight: 300;
          color: #9B9B9B;
          line-height: 22px;
        }
      }
    }
    //分页
    .pages{
      height: 94px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    //暂无数据
    .no_data{
      padding: 36px;
      img{
        width: 196px;
        height: 151px;
        object-fit: cover;
      }
    }
  }
}
</style>
